import { Injectable } from '@angular/core';
import { PromoTipoRequest } from '../interfaces/promoTipoRequest.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Pagination } from '../models/pagination';
import { PromoTipo } from '../models/promoTipo';
import { map } from 'rxjs/operators';
import { objsToCamelCase } from '../helpers/object.helper';
import * as global from './global.service';

@Injectable({
  providedIn: 'root'
})
export class PromoTipoService {

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
  }

  public index(promoTipoRequest?: PromoTipoRequest): Observable<Pagination> {

    let httpParams: HttpParams = new HttpParams();

    if (promoTipoRequest && promoTipoRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(promoTipoRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(global.API_URL + `/api/promoTypes`, this.httpOptions).pipe(
      map((response: any) => {

        response.data = objsToCamelCase<PromoTipo>(response.data);

        return response;
      })
    );
  }

  public create(promoTipo: PromoTipo) {

    const httpParams: HttpParams = new HttpParams();
    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.post<PromoTipo>(global.API_URL + '/api/promoTypes', promoTipo, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public update(promoTipo: PromoTipo) {

    const httpParams: HttpParams = new HttpParams();
    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.put<PromoTipo>(global.API_URL + '/api/promoTypes/' + promoTipo.id, promoTipo, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public delete(promoTipo: PromoTipo) {

    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.delete<any>(global.API_URL + '/api/promoTypes/' + promoTipo.id, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

}

import { PaginationRequest } from './paginationRequest.model';

export class DistritoRequest extends PaginationRequest {

  private administracionId: number;
  private administration: boolean;
  private barrios: boolean;

  constructor() {
    super();
  }

  /**
   * 
   */
  public getAdministracionId(): number {
    return this.administracionId;
  }

  /**
   * 
   */
  public setAdministracionId(administracionId: number): void {
    this.administracionId = administracionId;
  }

  /**
   * 
   */
  public getAdministracion(): boolean {
    return this.administration;
  }

  /**
   * 
   */
  public setAdministracion(administration: boolean): void {
    this.administration = administration;
  }

  /**
   * 
   */
  public getBarrios(): boolean {
    return this.barrios;
  }

  /**
   * 
   */
  public setBarrios(barrios: boolean): void {
    this.barrios = barrios;
  }
}
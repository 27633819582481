import { PaginationRequest } from "./paginationRequest.model";

export class UserRequest extends PaginationRequest {
  private userId: number;
  private staff: boolean;
  private staffs: boolean;
  private name: string;
  private email: string;
  private administracionNombre: string;
  private clienteNombre: string;
  private clienteId: number;
  private administracion: boolean;
  private cliente: boolean;
  private plan: boolean;

  constructor() {
    super();
  }

  public getUserId(): number {
    return this.userId;
  }

  public setUserId(id: number): void {
    this.userId = id;
  }

  /**
   *
   */
  public getStaff(): boolean {
    return this.staff;
  }

  /**
   *
   */
  public setStaff(staff: boolean): void {
    this.staff = staff;
  }

  /**
   *
   */
  public getStaffs(): boolean {
    return this.staffs;
  }

  /**
   *
   */
  public setStaffs(staffs: boolean): void {
    this.staffs = staffs;
  }

  public getClienteId(): number {
    return this.clienteId;
  }

  public setClienteId(id: number): void {
    this.clienteId = id;
  }

  public getName(): string {
    return this.name;
  }

  public setName(name: string): void {
    this.name = name;
  }

  public getEmail(): string {
    return this.email;
  }

  public setEmail(email: string): void {
    this.email = email;
  }

  public getAdministracionNombre(): string {
    return this.administracionNombre;
  }

  public setAdministracionNombre(administracionNombre: string): void {
    this.administracionNombre = administracionNombre;
  }

  public getClienteNombre(): string {
    return this.clienteNombre;
  }

  public setClienteNombre(clienteNombre: string): void {
    this.clienteNombre = clienteNombre;
  }

  public getAdministracion(): boolean {
    return this.administracion;
  }

  public setAdministracion(administracion: boolean): void {
    this.administracion = administracion;
  }

  public getCliente(): boolean {
    return this.cliente;
  }

  public setCliente(cliente: boolean): void {
    this.cliente = cliente;
  }

  public getPlan(): boolean {
    return this.plan;
  }

  public setPlan(plan: boolean): void {
    this.plan = plan;
  }
}

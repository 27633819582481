const MAX_LATITUDE = 90;
const MAX_LONGITUDE = 180;

export class Validations {
  public static hasNonBlankFields( item: any, fields: string[]): boolean {
    return fields.every( (field) => {
      return field in item &&
             this.isNonBlank( item[field]);
    });
  }

  public static hasAnyField( instance: any, fields: string[]): boolean {
    return fields.some( field => field in instance && instance[field]);
  }

  public static isNonBlank( value: any): boolean {
    return typeof value === 'string' &&
           value.trim() !== '';
  }

  public static isTimeValid( value: string): boolean {
    const parts = /(\d{2})\:(\d{2})/.exec( value);

    if (parts.length !== 3) {
      return false;
    }

    const hours = parseInt( parts[1]);

    if (hours < 0 && hours > 23) {
      return false;
    }

    const minutes = parseInt( parts[2]);

    if (minutes < 0 && minutes > 59) {
      return false;
    }

    return true;
  }

  public static isLatLngValid( lat: string, lng: string): boolean {
    const latitude = parseFloat( lat);
    const longitude = parseFloat( lng);

    return Math.abs( latitude) <= MAX_LATITUDE &&
           Math.abs( longitude) <= MAX_LONGITUDE;
  }

  public static onlyFirstIsBlank( value1: any, value2: any): boolean {
    return this.isBlank( value1) && !this.isBlank( value2);
  }

  public static isBlank( value?: string): boolean {
    return value === null ||
           typeof value === 'undefined' ||
           (typeof value === 'string' && value.trim() === '');
  }

  public static isUrlValid( value?: string): boolean {
    if (!value) {
      return true;
    }

    try {
      // tslint:disable-next-line: no-unused-expression
      new URL( value);
      return true;
    } catch ( _) {
      return false;
    }
  }
}

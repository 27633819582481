import { PaginationRequest } from './paginationRequest.model';

export class PreferenciaRequest extends PaginationRequest {

  private activo: boolean;

  constructor() {
    super();
  }

  /**
   * 
   */
  public getActivo(): boolean {
    return this.activo;
  }

  /**
   * 
   */
  public setActivo(activo: boolean): void {
    this.activo = activo;
  }

}
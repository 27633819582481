import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination';
import { PreferenciaRequest } from '../interfaces/preferenciaRequest.model';

import * as global from './global.service';
import { map } from 'rxjs/operators';
import { objsToCamelCase } from '../helpers/object.helper';
import { Preferencia } from '../models/preferencia';

@Injectable({
  providedIn: 'root'
})
export class PreferenciaService {

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

  }

  public index(preferenciaRequest: PreferenciaRequest) {

    let httpParams: HttpParams = new HttpParams();

    if (preferenciaRequest.getActivo()) {
      httpParams = httpParams.set('activo', String(preferenciaRequest.getActivo()));
    }

    if (preferenciaRequest.getPerPage()) {
      httpParams = httpParams.set('per_page', String(preferenciaRequest.getPerPage()));
    }

    if (preferenciaRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(preferenciaRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(global.API_URL + `/api/preferences`, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }
  public delete(preferencia: Preferencia) {

    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };
    return this.http.delete<any>(global.API_URL + '/api/preferences/' + preferencia.id, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public update(preferencia: Preferencia) {

    let httpParams: HttpParams = new HttpParams();
    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.put<Preferencia>(global.API_URL + '/api/preferences/' + preferencia.id, preferencia, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }
  public create(preferencia: Preferencia) {

    let httpParams: HttpParams = new HttpParams();
    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.post<Preferencia>(global.API_URL + '/api/preferences', preferencia, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination';
import { DistritoRequest } from '../interfaces/distritoRequest.model';
import * as global from './global.service';
import { map } from 'rxjs/operators';
import { objsToCamelCase } from '../helpers/object.helper';
import { Distrito } from '../models/distrito';

@Injectable({
  providedIn: 'root'
})
export class DistritoService {

  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
  }

  public index(distritoRequest: DistritoRequest) {

    let httpParams: HttpParams = new HttpParams();

    if (distritoRequest.getAdministracionId()) {
      httpParams = httpParams.set('administracionId', String(distritoRequest.getAdministracionId()));
    }

    if (distritoRequest.getAdministracion()) {
      httpParams = httpParams.set('administracion', String(distritoRequest.getAdministracion()));
    }

    if (distritoRequest.getBarrios()) {
      httpParams = httpParams.set('barrios', String(distritoRequest.getBarrios()));
    }

    if (distritoRequest.getPerPage()) {
      httpParams = httpParams.set('per_page', String(distritoRequest.getPerPage()));
    }

    if (distritoRequest.getOrderBy()) {
      httpParams = httpParams.set('order', String(distritoRequest.getOrderBy()));
    }

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.get<Pagination>(global.API_URL + `/api/distritos`, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public create(distrito: Distrito) {

    let httpParams: HttpParams = new HttpParams();

    httpParams = httpParams.set('barrios', String(true));

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.post<Distrito>(global.API_URL + '/api/distritos', distrito, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public update(distrito: Distrito) {

    let httpParams: HttpParams = new HttpParams();

    httpParams = httpParams.set('barrios', String(true));

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams
    };

    return this.http.put<Distrito>(global.API_URL + '/api/distritos/' + distrito.id, distrito, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

  public delete(distrito: Distrito) {

    this.httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };

    return this.http.delete<any>(global.API_URL + '/api/distritos/' + distrito.id, this.httpOptions).pipe(
      map((response: any) => {
        return objsToCamelCase(response);
      })
    );
  }

}

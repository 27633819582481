import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import * as global from "./global.service";
import { Pagination } from "../models/pagination";
import { objsToCamelCase } from "../helpers/object.helper";
import { Alergeno } from "../models/alergeno";
import { AlergenoRequest } from "../interfaces/alergenoRequest.model";

@Injectable({
  providedIn: "root",
})
export class AlergenosService {
  private httpOptions: object;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
    });
  }
  public index(alergenoRequest?: AlergenoRequest): Observable<Pagination> {
    let httpParams: HttpParams = new HttpParams();

    if (alergenoRequest && alergenoRequest.getOrderBy())
      httpParams = httpParams.set("orderBy", alergenoRequest.getOrderBy());

    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .get<Pagination>(global.API_URL + `/api/allergens`, this.httpOptions)
      .pipe(
        map((response: any) => {
          response.data = objsToCamelCase<Alergeno>(response.data);

          return response;
        })
      );
  }

  public create(alergeno: Alergeno) {
    const httpParams: HttpParams = new HttpParams();
    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .post<Alergeno>(
        global.API_URL + "/api/allergens",
        alergeno,
        this.httpOptions
      )
      .pipe(
        map((response: any) => {
          return objsToCamelCase(response);
        })
      );
  }

  public update(alergeno: Alergeno) {
    const httpParams: HttpParams = new HttpParams();
    this.httpOptions = {
      headers: this.httpHeaders,
      params: httpParams,
    };

    return this.http
      .put<Alergeno>(
        global.API_URL + "/api/allergens/" + alergeno.id,
        alergeno,
        this.httpOptions
      )
      .pipe(
        map((response: any) => {
          return objsToCamelCase(response);
        })
      );
  }

  public delete(alergeno: Alergeno) {
    this.httpOptions = {
      headers: this.httpHeaders,
      params: {},
    };

    return this.http
      .delete<any>(
        global.API_URL + "/api/allergens/" + alergeno.id,
        this.httpOptions
      )
      .pipe(
        map((response: any) => {
          return objsToCamelCase(response);
        })
      );
  }
}
